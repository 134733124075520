
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ILink } from '../shared/general/interfaces/ILink'
import BaseLink from './base/BaseLink.vue'

@Component({
  name: 'SocialMediaButtons',
  components: { BaseLink },
})
export default class SocialMediaButtons extends Vue {
 @Prop({ required: true }) buttons! : ILink[]
}
